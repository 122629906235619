import React from 'react';

function MultitapModal({ isVisible, onClose, onConfirm, cost }) {
  if (!isVisible) return null;

  return (
    <div class="modal autotap" onclick="void(0)">
        <div class="modal-content">
            <div class="modal-content__wrapper">
                <p class="modal-content__title">Увеличивает количество монет, которое вы можете заработать за одно нажатие.</p>
                <p class="modal-content__title">+1 монет за тап для следующего уровня.</p>
                <p class="modal-content__title">Купить автотап за {cost} монет?</p>
                <img src="assets/img/icons/close-popup.svg" alt="close" class="modal-content__close modal-close"/>
                <div className="modal-actions">
                  <button  class="button" onClick={onClose}>Отмена</button>
                  <button  class="button" onClick={onConfirm}>Подтвердить</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MultitapModal;
