import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Импортируем Link из react-router-dom
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal'; // Добавляем ErrorModal для отображения ошибок
import skyBg from '../assets/img/main-bg.png';
import boostIcon from '../assets/img/icons/boost.png';
import coinIcon from '../assets/img/icons/coin.svg';
import zoom from '../assets/img/monkeys/zoom.png';

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

const Game = ({ user, token, refreshUserData }) => {
  const [currentUser, setCurrentUser] = useState(user);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // Состояние для ошибки
  const [activeTab, setActiveTab] = useState('home'); // Состояние для активной вкладки
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [isLangDropdownVisible, setIsLangDropdownVisible] = useState(false);

  const toggleLangDropdown = () => {
    setIsLangDropdownVisible(!isLangDropdownVisible); // Переключение состояния
  }; // Функция для переключения выпадающего списка языков

  useEffect(() => {
    // Убедимся, что token и currentUser.user_id доступны
    if (currentUser && currentUser.user_id && token) {
      fetchUserData(token, currentUser.user_id)
        .then((userData) => {
          setCurrentUser(userData);
          setIsLoading(false); // Завершаем загрузку после получения данных
          updateProgressBar(userData.energy, userData.energy_limit); // Обновляем прогресс-бар
        })
        .catch((error) => {
          setError(`Failed to fetch user data: ${error.message}`);
          setIsLoading(false); // Также завершаем загрузку в случае ошибки
        });
    } else {
      setIsLoading(false); // Если token или user_id не доступны, завершаем загрузку
    }
  }, [currentUser.user_id, token]);

  const fetchUserData = async (token, userId) => {
    try {
      const response = await axios.get(`/api/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleMultiTap = async (e) => {
    e.preventDefault();
    const touchPoints = e.touches.length;
    if (currentUser.energy >= touchPoints * currentUser.click_value) {
      const updatedUser = {
        ...currentUser,
        coins: currentUser.coins + touchPoints * currentUser.click_value,
        energy: currentUser.energy - touchPoints * currentUser.click_value,
      };
      try {
        const res = await axios.post('/api/user/update', updatedUser, {
          headers: { Authorization: `Bearer ${token}` }, // Используем token из props
        });
        if (res.data.success) {
          setCurrentUser(updatedUser);
        }
      } catch (err) {
        console.error('Ошибка при обновлении пользователя:', err);
        setError('Failed to update user.');
      }
    }
  };

  const handleNavClick = (page) => {
    setActiveTab(page); // Устанавливаем активную вкладку
  };

  const updateProgressBar = (current, limit) => {
    const percentage = (current / limit) * 100;
    setProgressBarWidth(percentage);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />; // Показываем модальное окно при ошибке
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <span className="screen-bg__fade top"></span>
          <img src={skyBg} alt="bg" className="screen-bg__img" />
          <span className="screen-bg__fade bottom"></span>
        </div>
        <div className="screen-content">
          <div className="wrapper">
            <div className="home">
              <div className="home-top">
                <nav className="home-top__menu">
                  <Link to="/leaderboard" className="home-top__menu-link">Рейтинг</Link> {/* Ссылка на страницу лидерборда */}
                  <a href="#!" className="home-top__menu-link notify">р2р</a>
                  <a href="#!" className="home-top__menu-link disabled">НФТ (скоро)</a>
                  <div className="home-top__lang">
                    <button className="home-top__menu-link" onClick={toggleLangDropdown}>Язык</button>
                    {/* В зависимости от состояния отображаем или скрываем список языков */}
                    {isLangDropdownVisible && (
                      <div className="home-top__lang-content">
                        <a href="#" className="active">Русский</a>
                        <a href="#">English</a>
                      </div>
                    )}
                  </div>
                </nav>
                <p className="home-top__nickname">{currentUser.name_user_user_id}</p>
                <p className="home-top__balance">
                  <img src={coinIcon} alt="coin" className="home-top__balance-icon" />
                  <span>{currentUser.coins}</span>
                </p>
              </div>
              <div className="home-monkey">
                <div className="home-monkey__block">
                  <img src={zoom} alt="zoom" className="home-monkey__zoom" />
                  {/* Lottie Player for animation */}
                  <lottie-player
                    id="lottie-player"
                    src="assets/json/animation-full.json"
                    className="home-monkey__img"
                    background="transparent"
                    speed="1"
                    direction="1"
                    mode="normal"
                    loop
                  ></lottie-player>
                </div>
              </div>
              <div className="home-bottom">
                <div className="home-bottom__boost">
                  <p className="home-bottom__boost-number">
                    <img src={boostIcon} alt="boost" className="home-bottom__boost-icon" />
                    <span>
                      <span className="big">{currentUser.energy}</span>/{currentUser.energy_limit}
                    </span>
                  </p>
                  <div className="home-bottom__boost-bar">
                    <span
                      className="home-bottom__boost-progress"
                      style={{ width: `${progressBarWidth}%` }}
                    ></span>
                  </div>
                </div>
                <div className="home-bottom__lvl">
                  <div className="home-bottom__lvl-row">
                    <p className="home-bottom__lvl-number">01 Уровень</p>
                    <p className="home-bottom__lvl-upgrade">Повысить уровень</p>
                  </div>
                  <div className="home-bottom__lvl-dots">
                    {[...Array(40)].map((_, index) => (
                      <span key={index} className={`home-bottom__lvl-dot ${index > 30 ? 'disabled' : ''}`}></span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomNav user={currentUser} onNavClick={handleNavClick} />
    </main>
  );
};

export default Game;
