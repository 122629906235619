import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppRouter from './AppRouter';
import SplashScreen from './components/SplashScreen';
import ErrorModal from './components/ErrorModal';  // Импортируем ErrorModal для отображения ошибок

import './App.css';
import './PageStyles.css';

function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // Добавляем состояние для ошибки

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000); // Эмулируем загрузку данных, заставка исчезнет через 5 секунд

    // Инициализация Telegram Web App
    if (window.Telegram.WebApp.expand) {
      window.Telegram.WebApp.expand();
    } else {
      console.log("Метод expand() не поддерживается");
    }

    // Получение данных пользователя
    const userData = window.Telegram.WebApp.initDataUnsafe.user;
    if (userData && userData.id) {
      authenticateUser(userData.id);
    }
  }, []);

  // Функция для аутентификации пользователя и получения токена
  const authenticateUser = (userId) => {
    axios.post('/api/login', { user_id: userId })
      .then(res => {
        const accessToken = res.data.accessToken;
        setToken(accessToken); // Сохраняем токен в состояние
        localStorage.setItem('token', accessToken); // Сохраняем токен в localStorage
        fetchUserDetails(userId, accessToken);
      })
      .catch(err => {
        console.error('Ошибка при запросе к API:', err);
        setError('Ошибка при загрузке данных пользователя. Пожалуйста, попробуйте позже.'); // Устанавливаем сообщение об ошибке
      });
  };

  // Функция для получения данных пользователя
  const fetchUserDetails = (userId, accessToken) => {
    axios.get(`/api/user/${userId}`, {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
    .then(res => {
      setUser(res.data);
    })
    .catch(err => {
      console.error('Ошибка при загрузке данных пользователя:', err);
      setError('Ошибка при загрузке данных пользователя. Пожалуйста, попробуйте позже.'); // Устанавливаем сообщение об ошибке
    });
  };

  // Функция для обновления данных пользователя
  const refreshUserData = () => {
    if (user && token) {
      fetchUserDetails(user.user_id, token); // Обновляем данные пользователя
    }
  };

  useEffect(() => {
    // Если есть пользователь и его уровень изменился, обновляем данные
    if (user) {
      const interval = setInterval(() => {
        refreshUserData(); // Обновляем данные пользователя каждые 30 секунд, например
      }, 30000);
      
      return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
    }
  }, [user]); // Следим за изменениями состояния user

  if (isLoading) {
    return <SplashScreen />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />; // Отображаем модальное окно при ошибке
  }

  return (
    <AppRouter user={user} token={token} refreshUserData={refreshUserData} />
  );
}

export default App;
